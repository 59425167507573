import axios from 'axios';
import router from '../router';
import Vue from 'vue';
let baseURL = process.env.VUE_APP_BASE_URL;
const server = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    'content-type': 'application/json',
  },
});
var loadingToast = '';
let requestCount = 0;
let Toast = Vue.prototype.$totast;
// 请求拦截器
server.interceptors.request.use(config => { // 拦截请求，做统一处理
  // const token = localStorage.getItem('token');
  // 在每个http header都加上token
  config.headers.Authorization = '';
  if (config.url.indexOf('qiniu.com') !== -1) {
    config.headers.Authorization = config.data.token;
    config.headers.contentType = 'application/form-data';
  }
  if (!config.stopDefaultLoading) {
    if (requestCount === 0) {
      loadingToast = Toast({
        message: '加载中...',
        forbidClick: true,
      });
    }
  }
  requestCount++;
  return config;
}, err => { // 失败
  return Promise.reject(err);
});
// 响应拦截器
server.interceptors.response.use(response => { // 拦截响应，做统一处理
  requestCount--;
  if (requestCount === 0) {
    loadingToast.clear && loadingToast.clear();
  }
  if (
    (response.data.heads
      && (response.data.heads.code === 200 || response.data.heads.code === 300 || response.data.heads.code === 6001))
    || response.data.repCode === '0000'
    || response.data.repCode === '6111'
    || response.data.hash
  ) {
    return response.data;
  } else if (response.data.heads && (response.data.heads.code === 4012 || response.data.heads.code === 506)) {
    localStorage.clear();
    router.push('/');
    return Promise.reject(new Error('token已过期'));
  } else {
    Toast({
      showClose: true,
      message: response.data.heads.message,
      duration: 3000,
      title: '提示',
      type: 'error',
    });
    return Promise.reject(response.data.heads.message);
  }
}, err => { // 无响应时的处理
  requestCount--;
  if (requestCount === 0) {
    loadingToast.clear && loadingToast.clear();
  }
  if (axios.isCancel(err)) {
    return Promise.reject('请求已取消');
  }
  if (!err.status) {
    // https://github.com/axios/axios/issues/383
    return Promise.reject('未知异常');
  }
  var defaultMsg = '系统开了小差，请稍后再试';
  if (err.response && err.response.data && err.response.data.heads) {
    defaultMsg = err.response.data.heads.message || defaultMsg;
  }
  if (!window.navigator.onLine) {
    defaultMsg = '网络异常，请检查您的网络情况，重新再试！';
  }
  Toast({
    showClose: true,
    message: defaultMsg,
    duration: 3000,
    title: '提示',
    type: 'error',
  });
  return Promise.reject((err && err.response && err.response.status) || defaultMsg);
});
export default server;
