<template>
  <div id="register_box">
    <HOMEHEADER :isPC="isPC" @goAnchor="goAnchor" :showGoRegi="false" :scrollOptical="120" sourceType="regis" />
    <div class="reg_container">
      <!-- <div class="logo_box">
        <img class="logo_img" :src="require('../assets/images/pcHome/logo.png')" />
        <div v-if="isPC" class="logo_desc">开启医疗设备维修保养新体验</div>
      </div> -->
      <div class="form_box">
        <div class="title">{{ isPC ? '注册账号' : '立即注册' }}</div>
        <div class="form_li">
          <el-form
            ref="registerFormRef"
            :label-position="labelPosition"
            label-width="80px"
            :model="registerForm"
            :rules="rules"
          >
            <el-form-item label="身份选择" required prop="companyType">
              <el-radio-group
                :disabled="!!invitationRecordID"
                class="company_type"
                v-model="registerForm.companyType"
                @change="companyTypeChange"
                size="small"
              >
                <el-radio-button label="service">服务商</el-radio-button>
                <el-radio-button label="hospitals">医院</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="registerForm.companyType === 'service'" label="公司名称" required prop="certCode">
              <template v-if="invitationRecordID">
                <el-input disabled v-model="registerForm.companyName" />
              </template>
              <template v-else>
                <el-select
                  :disabled="!!invitationRecordID"
                  v-model="registerForm.certCode"
                  filterable
                  remote
                  clearable
                  :placeholder="`请选择公司名称（四字以上）`"
                  :remote-method="companyNameRemoteHandle"
                  @change="companyHandle"
                >
                  <el-option
                    v-for="item in companyList"
                    :key="item.CreditCode"
                    :label="item.Name"
                    :value="item.CreditCode"
                  ></el-option>
                </el-select>
              </template>
            </el-form-item>
            <el-form-item v-else label="医院名称" prop="customerId">
              <el-select
                v-model="registerForm.customerId"
                filterable
                clearable
                remote
                placeholder="请选择医院名称"
                :remote-method="customerKeyword"
                @change="customerHandle"
              >
                <el-option v-for="item in customerList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系人" required prop="ledgerApplicantName">
              <el-input
                :disabled="!!invitationRecordID"
                v-model="registerForm.ledgerApplicantName"
                placeholder="请输入联系人"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" required prop="ledgerApplicantPhone">
              <el-input
                :disabled="!!invitationRecordID"
                v-model="registerForm.ledgerApplicantPhone"
                :maxlength="11"
                placeholder="请输入联系人手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" required prop="code">
              <el-input
                style="display: inline-block"
                v-model="registerForm.code"
                maxlength="6"
                minlength="4"
                autocomplete="off"
                placeholder="请输入验证码"
              ></el-input>
              <template>
                <el-button
                  class="verification_code_btn"
                  size="mini"
                  type="info"
                  :disabled="registerForm.ledgerApplicantPhone.length !== 11"
                  @click="hanleShowVerify"
                >
                  {{ verificationCodeMessage }}
                </el-button>
              </template>
            </el-form-item>
            <el-form-item v-if="registerForm.companyType === 'service'" label="医院名称" prop="customerId">
              <template v-if="invitationRecordID">
                <el-input disabled v-model="registerForm.customerName" />
              </template>
              <template v-else>
                <el-select
                  v-model="registerForm.customerId"
                  filterable
                  clearable
                  remote
                  placeholder="请选择医院名称"
                  :remote-method="customerKeyword"
                  @change="customerHandle"
                >
                  <el-option
                    v-for="item in customerList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
            </el-form-item>
            <el-form-item v-if="isKeDu" label="推荐渠道" prop="referrerName">
              <el-input
                v-model="registerForm.referrerName"
                readonly
                placeholder="请输入推荐渠道"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item v-else-if="!!this.referrerNameLists.length" label="推荐人" prop="referrerName">
              <el-select v-model="registerForm.referrerName" placeholder="请选择推荐人">
                <el-option v-for="(item, index) in referrerNameLists" :key="index" :label="item" :value="index" />
              </el-select>
            </el-form-item>
            <el-form-item v-else-if="!$route.query.promotionCode" label="推荐人" prop="referrerName">
              <el-input v-model="registerForm.referrerName" placeholder="请输入推荐人"></el-input>
            </el-form-item>
            <el-collapse-transition v-if="registerForm.companyType === 'service'">
              <div v-if="companyScaleVisible">
                <el-form-item label="公司规模" prop="companySize">
                  <el-select clearable v-model="registerForm.companySize" placeholder="请选择公司规模">
                    <el-option
                      v-for="(item, index) in companySizeArr"
                      :key="index"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-collapse-transition>
            <el-row v-if="registerForm.companyType === 'service'">
              <div
                style="padding: 5px 20px"
                type="flex"
                justify="center"
                align="middle"
                @click="companyScaleVisible = !companyScaleVisible"
              >
                <span style="padding-right: 3px; font-size: 14px; color: rgba(36, 40, 50, 0.4)">
                  {{ companyScaleVisible ? '收起' : '展开更多' }}
                </span>
                <i
                  style="transform: rotate(-90deg); color: rgba(36, 40, 50, 0.4); font-size: 14px"
                  :class="companyScaleVisible ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'"
                />
              </div>
            </el-row>
            <el-form-item label-width="0px" prop="agreementChecked">
              <div class="clause">
                <el-checkbox v-model="registerForm.agreementChecked" />
                <span class="checked_text">{{ isPC ? '同意' : '已阅读并同意' }}</span>
                <a class="clause_href" @click.self.prevent="goToProtel(1)">《服务条款》</a>
                <a class="clause_href" @click.self.prevent="goToProtel(2)">《隐私协议》</a>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <button class="register_btn" :disabled='disabledRegister' @click="handleSubmit">提交注册申请</button>
      </div>
    </div>
    <!-- 图形验证码 -->
    <Verify
      @success="sendVerificationCode"
      mode="pop"
      captchaType="clickWord"
      :imgSize="{ width: '300px', height: '140px' }"
      ref="verify"
    ></Verify>
  </div>
</template>
<script>
import _ from 'lodash';
import Verify from '@/components/verifition/Verify';
import RegisterApi from '@/api/register';
import { isMobile } from '@/utils/util.js';
import HOMEHEADER from './HomeComponents/homeHeader.vue';
import { MessageBox } from 'element-ui';

export default {
  name: 'REGISTER_PAGE',
  components: {
    Verify,
    HOMEHEADER,
  },
  computed: {
    invitationRecordID: function () {
      const hash = location.hash;
      const search = `?${hash.split('?')[1]}`;
      let params = new URLSearchParams(search);
      return params.get('iRId');
    },
    rules: function () {
      const referrerName = this.referrerNameLists.length ? [{ required: true, message: '请选择推荐人' }] : [];
      const certCodeMsg = this.registerForm.companyType === 'service' ? '请输入公司名称' : '请输入医院名称';
      return {
        certCode: [{ required: true, message: certCodeMsg, trigger: 'change' }],
        ledgerApplicantName: [{ required: true, message: '请输入联系人名称' }],
        ledgerApplicantPhone: [{ required: true, message: '请输入手机号' }],
        companyName: [{ required: true, message: '请选择公司名称' }],
        code: [{ required: true, message: '请输入验证码' }],
        referrerName,
        agreementChecked: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请勾选服务条款及隐私协议'));
              }
              callback();
            },
            trigger: 'change',
          },
        ],
      };
    },
  },
  data () {
    const companySizeArr = ['10人以下', '10~50人', '50~100人', '100~500人', '500人以上'];
    return {
      isKeDu: false,
      disabledRegister: false,
      currentPageW: document.documentElement.clientWidth || document.body.clientWidth,
      isPC: true,
      labelPosition: 'top',
      companyScaleVisible: false,
      companyList: [],
      customerList: [],
      companySizeArr,
      interval: null,
      registerForm: {
        agreementChecked: false,
        companyName: '',
        certCode: undefined,
        ledgerApplicantName: '',
        ledgerApplicantPhone: '',
        code: '',
        customerName: '',
        customerId: '',
        customerCertCode: undefined,
        referrerName: '',
        companySize: '',
        companyType: 'service',
        referrerSource: isMobile() ? 'h5' : 'pc',
      },
      verificationCodeMessage: '获取验证码',
      promotionCode: '',
      referrerLists: [], // 推荐人列表
      referrerNameLists: [],
    };
  },
  async created () {
    if (this.invitationRecordID) {
      const { body } = await RegisterApi.getInviteDetail({ id: this.invitationRecordID });
      if (body) {
        this.registerForm.companyType = 'service';
        this.registerForm.companyName = body.serviceProviderName;
        this.registerForm.certCode = body.certCode;
        this.registerForm.ledgerApplicantName = body.contacts;
        this.registerForm.ledgerApplicantPhone = body.contactsPhone;
        this.registerForm.customerName = body.hospitalTenantName;
        this.registerForm.customerCertCode = body.hospitalTenantCertCode;
        this.registerForm.customerOriginId = body.hospitalTenantId;
      }
    }
    window.addEventListener('resize', _.debounce(this.resizeHandle, 100), true);
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('micromessenger') >= 0 && !this.getQueryString('code')) {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8d38a898145b664b&redirect_uri=${encodeURIComponent(
        location.href,
      )}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`;
      return;
    }
    if (this.$route.query.promotionCode) {
      this.promotionCode = this.$route.query.promotionCode || '';
      RegisterApi.getChannelDetail({ promotionCode: this.promotionCode }, true).then(res => {
        this.isKeDu = res.body.source === '1';
        if (this.isKeDu) {
          this.registerForm.referrerName = res.body.name;
        } else {
          this.getReferrerPageList();
        }
      });
    } else {
      this.promotionCode = '';
    }
    let isProtelPage = sessionStorage.getItem('isProtelPage');
    if (isProtelPage) {
      this.registerForm = JSON.parse(sessionStorage.getItem('registerForm'));
      sessionStorage.removeItem('isProtelPage');
      sessionStorage.removeItem('registerForm');
    }
  },
  mounted () {
    // 解决el-select ios 部分浏览器远端搜索不能唤起输入框
    Array.from(document.getElementsByClassName('el-select')).forEach(item => {
      item.children[0].children[0].removeAttribute('readOnly');
      item.children[0].children[0].onblur = function () {
        let _this = this;
        setTimeout(() => {
          _this.removeAttribute('readOnly');
        }, 200);
      };
    });
  },
  methods: {
    goAnchor (id) {
      this.$router.push({
        name: 'Home',
        params: { id },
      });
    },
    companyTypeChange () {
      this.registerForm.customerId = '';
      this.registerForm.customerName = '';
      this.registerForm.customerCertCode = '';
      this.registerForm.companySize = '';
      this.$nextTick(() => {
        this.$refs['registerFormRef']?.clearValidate();
      });
    },
    resizeHandle () {
      this.currentPageW = document.documentElement.clientWidth || document.body.clientWidth;
    },
    companyHandle (key) {
      this.registerForm.companyName = this.companyList.find(i => i.CreditCode === key)?.Name;
    },
    customerHandle (key) {
      const { name, enterpriseCertCode } = this.customerList.find(i => i.id === key);

      if (this.registerForm.companyType === 'service') {
        this.registerForm.customerName = name;
        this.registerForm.customerCertCode = enterpriseCertCode;
      } else {
        this.registerForm.companyName = name;
        this.registerForm.certCode = enterpriseCertCode;
      }
    },
    handleSubmit () {
      this.$refs.registerFormRef.validate().then(valid => {
        if (valid) {
          let currentInfo = this.registerForm;
          let referrerNameIndex = this.referrerNameLists.indexOf(this.registerForm.referrerName);
          let referrerId = referrerNameIndex > -1 ? this.referrerLists[referrerNameIndex].userId : '';

          currentInfo = {
            ...currentInfo,
            referrerId,
            promotionCode: this.promotionCode,
            stopLoading: true,
          };
          if (currentInfo.companyType === 'hospitals') {
            delete currentInfo.customerId;
            delete currentInfo.customerName;
            delete currentInfo.customerCertCode;
            delete currentInfo.companySize;
          }
          this.disabledRegister = true;
          RegisterApi.register(currentInfo, true).then(jsonData => {
            if (jsonData.heads?.code === 200) {
              let message = '请耐心等待审核，工作人员会1-3个工作日联系你，请保持手机畅通';
              let title = '申请提交成功';
              if (jsonData.body.approveFlag === '2') {
                message = `尊敬的${this.registerForm.ledgerApplicantName}，您已成功为${
                  this.registerForm.companyName
                }注册了医修保账号，登录信息即将发送至您尾号为${this.registerForm.ledgerApplicantPhone.substr(
                  7,
                  4,
                )}的手机号码，请注意查收`;
                title = '医修保申请已通过';
              }
              MessageBox.confirm(message, title, {
                dangerouslyUseHTMLString: true,
                cancelButtonText: '返回官网',
                confirmButtonText: '立即登录',
                showClose: false,
              })
                .then(() => {
                  const urlG = process.env.VUE_APP_LOGIN_URL;
                  window.location.href = urlG;
                })
                .catch(action => {
                  if (action === 'cancel') {
                    this.goAnchor('home');
                  }
                });
            }
          }).finally(() => {
            this.disabledRegister = false;
          });
        }
      });
    },
    getQueryString (name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    // 获取推荐人列表
    getReferrerPageList () {
      RegisterApi.getReferrerPageList({ promotionCode: this.promotionCode }, true).then(jsonData => {
        let currentLists = [];
        if (jsonData.heads.code === 200) {
          currentLists = jsonData.body || [];
        } else {
          this.$toast(jsonData.heads.message);
        }
        // 这里要区分 推荐人列表 数据为0 1  多个的情况
        this.referrerNameLists = currentLists.map(item => item.userName);
        this.registerForm.referrerName = this.referrerNameLists[0] || '';
        this.referrerLists = currentLists;
      });
    },
    // 发送验证码
    sendVerificationCode (info) {
      RegisterApi.sendValidCode(
        {
          phone: this.registerForm.ledgerApplicantPhone,
          captchaType: 'REGISTER',
          captchaVO: info,
        },
        true,
      ).then(this.sendVerificationCodeSuccessFun);
    },
    sendVerificationCodeSuccessFun (jsonData) {
      if (jsonData.heads.code === 200) {
        this.$toast('验证码发送成功！');
        this.updateVerification();
      } else {
        this.$toast(jsonData.heads.message, 'error');
      }
    },
    // 展开图形验证码
    hanleShowVerify () {
      if (this.interval) {
        return false;
      }
      this.registerForm.code = '';
      if (!/1\d{10}/.test(this.registerForm.ledgerApplicantPhone)) {
        this.$toast('请输入正确联系人手机号！');
        return;
      }
      if (this.registerForm.ledgerApplicantPhone < 11) {
        this.$toast('请输入正确联系人手机号！');
        return false;
      }
      this.$refs.verify.show();
    },
    // 倒计时
    updateVerification () {
      let s = 60;
      this.interval = setInterval(() => {
        s--;
        this.verificationCodeMessage = s + '(s)后重新获取';
        if (s === 0) {
          clearInterval(this.interval);
          this.verificationCodeMessage = '获取验证码';
          this.interval = null;
        }
      }, 1000);
    },
    companyNameRemoteHandle (query) {
      if (query && query.length >= 4) {
        RegisterApi.getCompanyInfo(query, true).then(res => {
          this.companyList = res.body || [];
        });
      } else {
        this.companyList = [];
      }
    },
    customerKeyword (query) {
      if (query) {
        RegisterApi.getCustomerList(query, true).then(res => {
          this.customerList = res.body || [];
        });
      }
    },

    // 跳转协议
    goToProtel (index) {
      sessionStorage.setItem('isProtelPage', 1);
      sessionStorage.setItem('registerForm', JSON.stringify(this.registerForm));
      let currentHref
        = index === 1 ? 'https://api.yxb.top/xzfwtk.html' : 'https://api.yxb.top/xzysxy.html';
      location.href = currentHref;
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeHandle, true);
  },
  watch: {
    '$route.query.promotionCode': function () {
      sessionStorage.removeItem('isProtelPage');
      sessionStorage.removeItem('registerForm');
      this.registerForm = this.$options.data().registerForm;
      this.promotionCode = this.$route.query.promotionCode || '';
      if (this.$route.query.promotionCode) {
        this.getReferrerPageList();
      }
    },
    currentPageW: {
      immediate: true,
      handler (val) {
        // 可视区域大于1200视为PC样式
        this.isPC = val > 1200;
        this.labelPosition = !this.isPC ? 'top' : 'right';
      },
    },
  },
};
</script>
<style lang="scss" scoped>
$mobileW: 1200px;
@media (min-width: $mobileW) {
  .el-select-dropdown {
    width: 315px;
  }

  .el-select-dropdown__item {
    display: block;
    width: 315px;
    overflow: hidden;
    word-break: normal;
    text-overflow: ellipsis;
  }

  .el-select-dropdown__item span {
    min-width: 205px;
    display: inline-block;
  }

  #register_box {
    width: 100%;
    min-height: 100vh;
    background: #eee;
    background: url('../assets/images/pcHome/regiBg.png') no-repeat 50% 50%;

    // padding-top: 10px;
    .reg_container {
      padding-bottom: 1px;
      width: 396px;
      margin: auto;
      padding-top: 145px;

      .logo_box {
        margin-bottom: 16px;

        .logo_img {
          display: inline-block;
          width: 146px;
          height: 48px;
          vertical-align: middle;
        }

        .logo_desc {
          display: inline-block;
          vertical-align: middle;
          border-left: 1px solid #aaa;
          font-size: 16px;
          font-weight: 500;
          color: #242832;
          line-height: 16px;
          letter-spacing: 1px;
          margin-left: 10px;
          padding-left: 10px;
        }
      }

      .form_box {
        margin-bottom: 50px;
        min-height: 300px;
        background: #fff;
        padding: 13px 30px 20px;

        .title {
          margin-bottom: 10px;
        }

        /deep/ .form_li {
          .verification_code_btn {
            position: absolute;
            right: 0;
            bottom: 7px;
            color: #0f89ff;
            border: none;
            background: transparent;
            font-size: 14px;
            font-weight: 400;
          }

          .el-input__inner {
            border: none;
            border-bottom: 1px solid #ccc;
            border-radius: 0;
            line-height: 25px;
          }

          .el-form-item__label {
            padding: 0;
            line-height: 40px;
            padding-right: 10px;
          }

          .el-form-item__content {
            line-height: 25px;
          }

          .el-select {
            display: block;
          }

          .company_type {
            display: flex;
            width: 100%;

            .el-radio-button {
              flex: 0 0 49%;

              .el-radio-button__inner {
                display: block;
              }
            }
          }
        }

        .clause {
          margin-bottom: 16px;
          line-height: 24px;
          font-size: 14px;

          .checked_text {
            padding-left: 10px;
            color: rgba(43, 38, 48, 0.6);
          }

          .clause_href {
            cursor: pointer;
            color: #0f89ff;
          }
        }

        .clause_tips {
          color: #ff0000;
          font-size: 12px;
        }

        .register_btn {
          height: 44px;
          // line-height: 44px;
          width: 100%;
          cursor: pointer;
          margin: 5px auto 0;
          color: #ffffff;
          font-size: 16px;
          border-radius: 4px;
          background: #0f89ff;
          font-weight: 400;
          border: none;
          outline: none;
        }

        .register_btn:disabled {
          background: rgba(15, 137, 255, 0.7);
        }
      }
    }
  }
}

@media (max-width: $mobileW) {
  .el-select-dropdown {
    width: 315px;
  }

  .el-select-dropdown__item {
    display: block;
    width: 315px;
    overflow: hidden;
    word-break: normal;
    text-overflow: ellipsis;
  }

  .el-select-dropdown__item span {
    min-width: 205px;
    display: inline-block;
  }

  #register_box {
    min-height: 100vh;

    .reg_container {
      min-height: 100vh;
      background: #fff;

      .logo_box {
        line-height: 56px;
        height: 56px;
        border-bottom: 1px solid #eee;

        .logo_img {
          display: inline-block;
          padding-left: 16px;
          vertical-align: middle;
          width: 100px;
          height: 32px;
        }
      }

      .form_box {
        box-sizing: border-box;
        padding: 18px 30px 30px;
        min-height: 500px;

        .title {
          font-weight: 500;
          color: #222426;
          line-height: 40px;
          margin-bottom: 12px;
        }

        /deep/ .form_li {
          padding-bottom: 25px;

          .company_type {
            display: flex;
            width: 100%;

            .el-radio-button {
              flex: 0 0 49%;

              .el-radio-button__inner {
                display: block;
              }
            }
          }

          .verification_code_btn {
            position: absolute;
            right: 0;
            bottom: 0;
            color: #0f89ff;
            border: none;
            background: transparent;
            font-size: 16px;
            font-weight: 400;
          }

          .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
            content: '';
            display: none;
          }

          .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
            content: '*';
            color: #f56c6c;
            margin-left: 4px;
          }

          .el-select {
            display: block;
          }

          .el-form-item {
            margin-bottom: 18px;
          }

          .el-input__inner {
            border: none;
            border-bottom: 1px solid #ccc;
            border-radius: 0;
            line-height: 25px;
            height: 25px;
          }

          .el-form-item__label {
            padding: 0;
            line-height: 25px;
            text-indent: 5px;
          }

          .el-form-item__content {
            line-height: 25px;
          }
        }

        .clause {
          margin-bottom: 16px;
          line-height: 12px;
          font-size: 12px;

          .checked_text {
            padding-left: 6px;
            color: rgba(36, 40, 50, 0.6);
          }

          .clause_href {
            color: #0f89ff;
          }
        }

        .clause_tips {
          color: #ff0000;
          font-size: 12px;
        }

        .register_btn {
          height: 42px;
          // line-height: 44px;
          width: 100%;
          margin: 5px auto 0;
          color: #ffffff;
          font-size: 16px;
          border-radius: 4px;
          background: #0f89ff;
          font-weight: 400;
          border: none;
          outline: none;
        }

        .register_btn:disabled {
          background: rgba(15, 137, 255, 0.7);
        }
      }
    }
  }
}
</style>
