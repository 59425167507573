/*
 * @Date: 2021-09-16 10:04:12
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-16 10:04:15
 * @Description: 登录页面
 * @FilePath: \maintenance\src\api\login\login.js
 */
// 获取图形验证码
import server from '@/axios/server';
export default {
  // 获取医院医院名称信息
  getCustomerList (keyWord, stopDefaultLoading = false) {
    return server.post(
      '/xiaozi-xmb/wireless/saas/customer/hospital/page',
      { name: keyWord, pageNum: 1 },
      { stopDefaultLoading },
    );
  },
  // 获取推广渠道详情
  getChannelDetail (data, stopDefaultLoading = false) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/getChannelDetail', data, { stopDefaultLoading });
  },
  getCaptcha (data) {
    return server.post('/xiaozi-xmo/captcha/get', data, { stopDefaultLoading: true });
  },
  // 验证校验码
  checkCaptcha (data) {
    return server.post('/xiaozi-xmo/captcha/check', data, { stopDefaultLoading: true });
  },
  // 发送短信
  sendValidCode ({ captchaVO, phone, captchaType }, stopDefaultLoading = false) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/sendCaptcha', { captchaVO, phone, captchaType }, { stopDefaultLoading });
  },
  // 提交注册
  register (param, stopDefaultLoading = false) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/register', param, { stopDefaultLoading });
  },
  // 获取推荐人列表
  getReferrerPageList (param, stopDefaultLoading = false) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/getReferrerPage', param, { stopDefaultLoading });
  },
  // 获取产品列表
  getProductList (params, stopDefaultLoading = false) {
    return server.post(
      '/xiaozi-xmo/wireless/saas/product/page',
      {
        pageFlag: false,
        ...params,
        orderBy: [
          {
            orderByProperty: 'onlineTime',
            orderByType: 'ASC',
          },
        ],
      },
      { stopDefaultLoading },
    );
  },

  // 获取企查查公司信息
  getCompanyInfo (keyWord, stopDefaultLoading = false) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/qcc/page', { keyWord, pageNum: 1 }, { stopDefaultLoading });
  },
  // 获取支付链接
  getPayUrl (data) {
    return server.post('/xiaozi-xmb/wireless/saas/order/getPayUrl', data);
  },
  // 查询支付结果
  getPayResut (data) {
    return server.post('/xiaozi-xmb/wireless/saas/order/checkPay', data, { stopDefaultLoading: true });
  },

  // 获取微信内支付js_api鉴权信息
  getWXSign (url) {
    return server.post('/xiaozi-xmb/wireless/saas/order/getSignature', { url });
  },
  // 获取微信支付 checkPay对应传参
  getJSApi ({ id, buyerId, payMethod, code }) {
    return server.post('/xiaozi-xmb/wireless/saas/order/getJsApi', { id, buyerId, payMethod, code });
  },
  // 查询订单详情
  getOrderDetail (data) {
    return server.post('/xiaozi-xmb/wireless/saas/order/queryDetailForOut', data);
  },
  // 查询订单详情
  getInviteDetail (data) {
    return server.post('/xiaozi-xmb/wireless/saas/serviceProvider/invitation/detail', data, {
      stopDefaultLoading: true,
    });
  },
};
